<template>
    <section>
        <Upload
            :type="multiple ? 'select' : 'drag'"
            :name="name"
            :multiple="multiple"
            :show-upload-list="false"
            :format="format"
            :max-size="maxSize"
            :action="action"
            :headers="headers"
            :data="params"
            :on-progress="uploading"
            :on-success="success"
            :on-error="error"
            :on-format-error="formatError"
            :on-exceeded-size="sizeError"
            :disabled="disabled"
        >
            <template v-if="multiple">
                <Button shape="circle" icon="md-cloud-upload" :loading="loading">{{ text }}</Button>
            </template>
            <template v-else>
                <div class="pd-xs radius">
                    <div class="pd-tb" v-if="loading">
                        <Spin>
                            <Icon type="ios-loading" size="36" class="spin-icon-load"></Icon>
                            <p>Uploading</p>
                        </Spin>
                    </div>
                    <div v-else>
                        <div v-if="preview" class="multiple-upload-image-wrap">
                            <Icon v-if="!disabled" type="md-close-circle" class="trash-image" size="16" @click.stop="delImage"></Icon>
                            <img :src="preview" class="preview-img" >
                        </div>
                        <template v-else>
                            <Icon type="md-cloud-upload" size="26" class="text-primary" />
                            <p class="text-blue-grey" v-if="text">{{ text }}</p>
                        </template>
                    </div>
                </div>
            </template>
        </Upload>

        <template v-if="multiple">
            <Row :gutter="15" class="mt" v-if="value.length">
                <Col :span="8" class="mg-tb-xs" v-for="(item, index) in value" :key="index">
                    <div class="multiple-upload-image-wrap text-center">
                        <Icon v-if="!disabled" type="md-close-circle" class="trash-image" size="16" @click.native="removeImage(index)"></Icon>
                        <img :src="item" class="preview-img">
                        <ButtonGroup class="mg-tb-xs" size="small">
                            <Button icon="md-arrow-back" @click="sort(index, 'up')"></Button>
                            <Button icon="md-arrow-forward" @click="sort(index, 'down')"></Button>
                        </ButtonGroup>
                    </div>
                </Col>
            </Row>
        </template>
    </section>
</template>

<script>
    export default {
        props: {
            value: {
                type: [ String, Array ],
                required: true
            },
            name: {
                type: String,
                default: 'image'
            },
            maxSize: {
                type: Number,
                default: 2048
            },
            format: {
                type: Array,
                default()
                {
                    return ['jpg', 'jpeg', 'png', 'gif']
                }
            },
            width: {
                type: Number,
            },
            height: {
                type: Number,
            },
            multiple: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            text: {
                type: String,
                default: '上传图片'
            }
        },
        data()
        {
            return {
                currentValue : this.value,
                preview      : false,
                action       : this.$http.defaults.baseURL + '/upload/image',
                headers      : { Authorization: window.localStorage.getItem('token') },
                params       : {},
                loading      : false,
                disabled     : this.disabled
            }
        },
        created()
        {
            if (this.width)
                this.params.width = this.width

            if (this.height)
                this.params.height = this.height
        },
        mounted()
        {
            this.showPreview()
        },
        methods: {
            uploading(event, file)
            {
                this.loading = true
            },
            success(res, file)
            {
                if (res.status == 200)
                {
                    if (this.multiple)
                    {
                        this.currentValue.push(res.data[this.name])
                    }
                    else
                    {
                        this.currentValue = res.data[this.name]
                    }
                    
                    this.updateValue()
                    this.$emit("on-upload-success", this.currentValue);
                }
                else
                {
                    this.$Modal.error({ title: '上传失败', content: res.message })
                }

                this.loading = false
            },
            error(error, file)
            {
                this.loading = false

                this.$Modal.error({
                    title : '上传失败',
                    content  : error
                })
            },
            formatError(file)
            {
                this.$Modal.warning({
                    title   : '格式不支持',
                    content : '只允许上传 ' + this.format.join('、') + ' 格式的图片文件'
                })
            },
            sizeError(file)
            {
                this.$Modal.warning({
                    title   : '超出文件大小限制',
                    content : '文件不能大于 ' + (this.maxSize / 1024) + 'M'
                })
            },
            showPreview()
            {
                this.preview = this.currentValue
            },
            delImage()
            {
                this.preview = false
                this.currentValue = ''
                this.updateValue()
            },
            removeImage(index)
            {
                this.currentValue.splice(index, 1)
                this.updateValue()
            },
            sort(index, mode)
            {
                if (mode == 'up' && index == 0)
                    return

                if (mode == 'down' && index == this.currentValue.length - 1)
                    return

                var item = this.currentValue[index]
                if (mode == 'up')
                {
                    this.currentValue[index] = this.currentValue[index - 1]
                    this.currentValue[index - 1] = item
                }
                else
                {
                    this.currentValue[index] = this.currentValue[index + 1]
                    this.currentValue[index + 1] = item
                }

                this.$forceUpdate()
                this.updateValue()
            },
            updateValue()
            {
                this.$emit('input', this.currentValue)
            }
        },
        watch: {
            value(value)
            {
                this.currentValue = this.value
                this.showPreview()
            },
            currentValue(value)
            {
                this.showPreview()
            },
            width(value)
            {
                if (value)
                    this.params.width = this.width
            },
            height(value)
            {
                if (value)
                    this.params.height = this.height
            }
        }
    }
</script>

<style>
    .multiple-upload-image-wrap {
        border: 1px solid #e0e0e0;
        padding: 2px;
        border-radius: 4px;
        position: relative;
    }
    .multiple-upload-image-wrap .trash-image {
        position: absolute;
        top: -8px;
        right: -8px;
        cursor: pointer;
    }
    .multiple-upload-image-wrap .preview-img {
        display: block;
        width: 100%;
        border-radius: 4px;
        /*max-height: 20vh;*/
    }
</style>